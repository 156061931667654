import { ModalName } from '@skeleton/consts/modals';

interface IAuthorizationStore {
  canAuthorizeWithEmail: boolean;
  canAuthorizeWithPhone: boolean;
  requireEmailVerification: boolean;
}

export const useAuthorization = defineStore('authorization', {
  state: (): IAuthorizationStore => ({
    canAuthorizeWithEmail: false,
    canAuthorizeWithPhone: false,
    requireEmailVerification: false,
  }),

  actions: {
    updateAuthState(view: Exclude<keyof IAuthorizationStore, 'registrationEmail'>, value: boolean) {
      this[view] = value;
    },

    async resendVerificationEmail() {
      const { resendVerifyEmail } = useCoreProfileApi();
      const { closeModal, openModal } = useModalStore();

      try {
        openModal(ModalName.CONFIRM_EMAIL);
        resendVerifyEmail();
      } catch (error) {
        closeModal(ModalName.CONFIRM_EMAIL);
      }
    },
  },
  getters: {
    isEmailVerified() {
      const { profile } = storeToRefs(useProfileStore());
      return !!profile.value?.confirmedAt || !!profile.value?.tgUserId;
    },
  },
});
