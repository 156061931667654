<template>
  <div class="tabs">
    <div
      v-for="tab in tabs"
      :key="tab.key"
      :class="{ 'tabs--header': true, active: isActiveTab(tab) }"
      @click="selectTab(tab.key)"
    >
      <div
        v-if="tab.canShow"
        class="tabs__title"
        :class="{'tabs__title--active': isActiveTab(tab) }"
      >
        <atomic-icon
          :id="tab.icon"
          :class="['tabs__icon', {'tabs__icon--active': isActiveTab(tab) }]"
        />

        <span>
          {{ tab.title }}
        </span>
      </div>
    </div>
  </div>

  <slot
    v-for="(tab, index) in tabs"
    :key="index"
    :name="tab.isActive && tab.key"
  />
</template>

<script lang="ts" setup>
  import { ref, onMounted } from 'vue';

  interface Tab {
    key: string;
  }

  interface IProps {
    tabs: {
      title: string;
      isActive: boolean;
      key: string;
      icon: string;
      canShow: boolean
    }[];
  }

  const props = defineProps<IProps>();
  const selectedTab = ref<string | null>(null);

  watchEffect(() => {
    props.tabs.find(tab => {
      if (tab.isActive) {
        selectedTab.value = tab.key;
        return true;
      }
    });
  });


  const isActiveTab = ({ key }: Tab): boolean => {
    return selectedTab.value === key
  }

  const selectTab = (key: string) => {
    if (selectedTab.value === key) return;
    selectedTab.value = key;
    props.tabs.forEach(tab => {
      tab.isActive = tab.key === key;
    });
  };

  onMounted(() => {
    if (props.tabs.length > 0) {
      selectTab(props.tabs[0].key);
    }
  });
</script>

<style src="~/assets/styles/components/authorization/tabs.scss" lang="scss" />
